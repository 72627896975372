import { ENDPOINTS } from 'config/api';
import fetch from 'app/utilities/fetch';
import { mergeMap  } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';

export const INITIAL_STATE = {
    cache: {},
    eventType: ''
};

// Actions
export const FETCH_ROOMS = 'rfa-conventions-website/rooms/FETCH_ROOMS';
export const SET_EVENT_TYPE = 'rfa-conventions-website/rooms/SET_EVENT_TYPE';
export const FETCH_ROOMS_SUCCESS = 'rfa-conventions-website/rooms/FETCH_ROOMS_SUCCESS';

// Action Creators
export const fetchRooms = (venueId, eventType) => ({
    type: FETCH_ROOMS,
    venueId,
    eventType
});

export const setEventType = (eventType) => ({
    type: SET_EVENT_TYPE,
    eventType
});

export const fetchRoomsSuccess = (eventType, roomsList) => ({
    type: FETCH_ROOMS_SUCCESS,
    eventType,
    roomsList
});

// Reducers
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_EVENT_TYPE:
            return { ...state, eventType: action.eventType };
        case FETCH_ROOMS_SUCCESS:
            return {
                ...state,
                cache: {
                    ...state.cache,
                    [action.eventType]: action.roomsList
                }
            };
        default:
            return state;
    }
};

// Epic creator
/**
 * @callback roomsEndpoint
 * @param {string} eventType
 * @param {string} capacity
 * @returns {string} endpoint - generated rooms endpoint
 */

/**
 * @callback fetchRoomsSuccessAction
 * @param {string} eventType
 * @returns {object} action
 */

/**
 * @callback formatResult
 * @param {object} response
 * @returns {array} roomsResults - rooms result array
 */

/**
 * Creates Rooms epic
 * @param {roomsEndpoint} roomsEndpoint - rooms endpoint generator
 * @param {formatResult} formatResult - response formatter function
 * @param {fetchRoomsSuccess} fetchRoomsSuccessAction - action creator
 */
export const createRoomsEpic = (roomsEndpoint, formatResult, fetchRoomsSuccess) => {
    return (action$) => action$.pipe(
        ofType(FETCH_ROOMS),
        mergeMap(({ eventType, venueId }) => {
            return (
                fetch(roomsEndpoint(eventType, null, null, venueId))
                    .then((response) => {
                        return fetchRoomsSuccess(eventType, formatResult(response));
                    })
                    .catch(() => {
                    // TODO: Add a better way to treat errors
                        return fetchRoomsSuccess(eventType, formatResult([]));
                    })
            );
        })
    );
};

// Helpers
export const formatResult = (response) => {
    if (response && response.data && response.data.length) {
        return response.data[0].attributes.rooms;
    }

    return [];
};

// Epics
const roomsEpic = createRoomsEpic(
    ENDPOINTS.FILTER,
    formatResult,
    fetchRoomsSuccess
);

export const epics = combineEpics(roomsEpic);
