/* eslint-disable require-jsdoc */
export function selectIsBusy({ rooms }) {
    const { cache, eventType } = rooms;

    return !cache.hasOwnProperty(eventType);
}

export function selectEventType({ rooms }) {
    return rooms.eventType;
}

export function selectRoomsResults(eventType, { rooms }) {
    if (rooms && rooms.cache && rooms.cache.hasOwnProperty(eventType)) {
        const { cache } = rooms;

        return cache[eventType];
    }

    return [];
}
